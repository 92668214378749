import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    API_RESOURCE_USER_OFFER_DIAGNOSTIC_REPORT, 
    API_RESOURCE_USERS, 
    API_RESOURCE_PRODUCTS, 
    API_RESOURCE_LOCATIONS,
    API_RESOURCE_PRODUCT,
} from 'Consts/apiResources';
import { USER_OFFER_SELL_PROPABILITIES } from 'Consts/userOffers';
import { USER_ROLE_TRAINER } from 'Consts/userRoles';
import { ENDPOINT_TRAINER_FILE_PRESIGN } from 'Consts/api';
import { OFFER_PERIOD_TYPE_RECURRING } from 'Consts/offers';
import { TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { fromSelectObject, filterKeys } from 'Utils/object';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { getName as getLocationName } from 'Utils/location';
import { getPeriodTypeLabel, getDurationTypeLabel, getTypeLabel } from 'Utils/offer';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElement';
import { TAB_DIAGNOSTIC_REPORTS } from 'Components/pages/trainer/UsersManage/component';
import PaginatedList from 'Components/layout/panel/PaginatedList';

export default class TrainerUserOfferDiagnosticReportsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            listOffers: PropTypes.func.isRequired,
            listUserOffers: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
            singleProduct: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
        offers: PropTypes.object.isRequired,
        onMapFilters: PropTypes.func,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
        onMapFilters: value => value,
    };

    state = {
        formState: {},
        selectedProduct: null,
        selectedOffer: {},
    };

    defaultQuery = {
        search: '',
        page: 1,
        perPage: 5,
        orderBy: 'alphabetical',
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { data } = this.props;

        this.loadData();

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, predefinedState, actions } = this.props;
        const { formState, selectedOffer } = this.state;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (
            data && JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
            predefinedState && JSON.stringify(predefinedState) !== JSON.stringify(prevProps.predefinedState)
        ) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }

        if(prevState?.formState?.productId && JSON.stringify(formState.offerId) !== JSON.stringify(prevState.formState.offerId)) {
            this.setState({
                formState: {
                    ...formState,
                    productId: null,
                },
            });
        }

        if(!prevState?.formState?.productId && JSON.stringify(formState.productId) !== JSON.stringify(prevState.formState.productId)) {
            actions.singleProduct({
                id: formState?.productId?.value,
            }).then(response => {
                this.setState({ 
                    selectedProduct: response.payload[API_RESOURCE_PRODUCT],
                });
            });
        }

        if (prevState?.selectedOffer.id !== selectedOffer.id) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listOffers({ ...queryObject });
        }, 500);
    }

    formStateToApi = data => ({
        ...data,
        sellProbability: fromSelectObject(data.sellProbability),
        leadId: fromSelectObject(data.leadId),
        userId: fromSelectObject(data.userId),
        userOfferId: fromSelectObject(data.userId),
        nextEventDate: dateToApiFormat(data.nextEventDate, 'datetime', true),
        productId: fromSelectObject(data.productId),
        locationId: fromSelectObject(data.locationId),
        fileIds: Array.isArray(data.files)
            ? data.files.map(file => file.id)
            : [],
    })

    dataToFormState = data => {
        const { predefinedState } = this.props;
        const { selectedOffer } = this.state;

        return {
            ...data,
            ...predefinedState,
            leadId: data.lead
                ? { 
                    label: getFullName(data.lead).label,
                    value: data.lead.id,
                } 
                : null,
            offerId: selectedOffer
                ? selectedOffer.id
                : null,
            productId: data.product
                ? { value: data.product.id, label: data.product.name }
                : null,
            locationId: data.location
                ? { value: data.location.id, label: data.location.name }
                : null,
            userOfferId: data.userOffer
                ? { value: data.userOffer.id, label: data.userOffer.name }
                : null,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history, location } = this.props;

        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return actions.create({
            ...this.formStateToApi(formState),
            userId: queryObject.userId,
            userOfferId: queryObject.userOfferId || formState.userOfferId?.value,
        })
            .then(response => {
                history.push(
                    withVariables(
                        TRAINER_ADEPTS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_USER_OFFER_DIAGNOSTIC_REPORT].user.id },
                        { tab: TAB_DIAGNOSTIC_REPORTS }
                    )
                );
            });
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            ...this.formStateToApi(formState),
        });
    }

    render() {
        const { data, location, history, actions, offers, onMapFilters } = this.props;
        const { formState, selectedProduct, selectedOffer } = this.state;

        return (
            <StyledComponent
                className="trainer-user-offer-diagnostic-reports-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    styleVersion={2}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        submitButtonStyle: 'gradient',
                        styleVersion: 'transparentLight',
                        elements: [{
                            type: 'textarea',
                            name: 'diagnose',
                            label: 'Diagnoza',
                            inputProps: {
                                styleVersion: 1,
                            },
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'trainingTarget',
                            label: 'Cel treningowy',
                            inputProps: {
                                styleVersion: 1,
                            },
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'homework',
                            label: 'Praca domowa',
                            inputProps: {
                                styleVersion: 1,
                            },
                            required: true,
                        }, {
                            type: 'element',
                            key: 'offerId',
                            children: (
                                <PaginatedList
                                    location={location}
                                    history={history}
                                    collection={offers}
                                    title={'Pakiet treningowy'}
                                    onMapElement={element => (
                                        <PaginatedListElement
                                            key={element.id}
                                            title={element.originalName}
                                            controls={[{
                                                type: 'button',
                                                label: selectedOffer.id === element.id ? 'Wybrano' : 'Wybierz',
                                                visible: true,
                                                onClick: () => {
                                                    this.setState({
                                                        selectedOffer: element,
                                                    });
                                                },
                                            }]}
                                            additionals={[{
                                                visible: true,
                                                name: 'Cena',
                                                value: formatPrice(element.totalPriceGross),
                                            }, {
                                                name: 'Typ',
                                                value: getTypeLabel(element).label,
                                            }, {
                                                visible: true,
                                                name: 'Cykliczność',
                                                value: getPeriodTypeLabel(element).label
                                            }, {
                                                visible: element.periodType === OFFER_PERIOD_TYPE_RECURRING,
                                                name: 'Cykl płatności',
                                                value: `${element.durationValue} (${getDurationTypeLabel(element).label})`,
                                            }, {
                                                visible: element.periodType === OFFER_PERIOD_TYPE_RECURRING,
                                                name: 'Okres wypowiedzenia',
                                                value: `${element.noticePeriodDurationValue} (${getDurationTypeLabel({ durationType: element.noticePeriodDurationType }).label})`,
                                            }]}
                                        />
                                    )}
                                    filters={onMapFilters([{
                                        name: 'search',
                                        label: 'Szukaj',
                                        type: 'text',
                                    }])}
                                />
                            )
                        }, {
                            type: 'select',
                            name: 'sellProbability',
                            label: 'Prawdopodobieństwo sprzedaży',
                            options: USER_OFFER_SELL_PROPABILITIES.map(option => ({ 
                                label: option.label,
                                value: option.key,
                            })),
                            inputProps: {
                                style: 'default',
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'nextEventDate',
                            label: 'Data następnego treningu',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd HH:mm',
                                    showTimeSelect: true,
                                    timeIntervals: 15,
                                },
                                style: 'default',
                            },
                        }, {
                            type: 'select',
                            name: 'productId',
                            label: 'Produkt następnego treningu',
                            isVisible: Boolean(formState.offerId && selectedOffer),
                            inputProps: {
                                style: 'default',
                                dynamic: true,
                                loadOnOnLoadChange: true,
                                onLoad: query => actions.listProducts({
                                    search: query,
                                    offerId: selectedOffer.id || null,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_PRODUCTS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                }),
                            },
                            required: Boolean(formState.locationId || formState.nextEventDate),
                        }, {
                            type: 'select',
                            name: 'locationId',
                            label: 'Lokacja następnego treningu',
                            isVisible: Boolean(formState.productId && selectedProduct),
                            inputProps: {
                                style: 'default',
                                dynamic: true,
                                onLoad: query => actions.listLocations({
                                    search: query,
                                    enabled: true,
                                    types: [
                                        selectedProduct?.locationType,
                                        selectedProduct?.secondLocationType,
                                        selectedProduct?.thirdLocationType,
                                    ],
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_LOCATIONS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getLocationName(element).label,
                                }),
                            },
                            required: Boolean(formState.productId || formState.nextEventDate),
                        }, {
                            type: 'select',
                            name: 'leadId',
                            label: 'Proponowany prowadzący',
                            isVisible: true,
                            inputProps: {
                                style: 'default',
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_TRAINER,
                                    canLeadProductId: fromSelectObject(formState.productId) || data && data.product && data.product.id,
                                    locationId: fromSelectObject(formState.locationId) || data && data.location && data.location.id,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz',
                            inputProps: {
                                styleVersion: 1,
                            },
                        }, {
                            type: 's3FileMultiUpload',
                            name: 'files',
                            label: 'Pliki',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_TRAINER_FILE_PRESIGN
                                        .replace('{type}', 'userOfferDiagnosticReportFile'),
                                },
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}