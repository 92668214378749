import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    single,
    create,
    presignFile,
} from 'Redux/modules/trainer/userOfferDiagnosticReports/actions';

import {
    list as listUsers,
} from 'Redux/modules/trainer/users/actions';

import {
    list as listOffers,
} from 'Redux/modules/trainer/offers/actions';

import {
    list as listUserOffers,
} from 'Redux/modules/trainer/userOffers/actions';

import {
    list as listProducts,
    single as singleProduct,
} from 'Redux/modules/trainer/products/actions';

import {
    list as listLocations,
} from 'Redux/modules/trainer/locations/actions';

export default connect(
    state => ({
        offers: state.trainerOffers.list,
    }),
    dispatch => ({
        actions: {
            update: dispatch(update),
            single: dispatch(single),
            create: dispatch(create),
            presignFile: dispatch(presignFile),
            listUsers: dispatch(listUsers),
            listOffers: dispatch(listOffers),
            listUserOffers: dispatch(listUserOffers),
            listProducts: dispatch(listProducts),
            listLocations: dispatch(listLocations),
            singleProduct: dispatch(singleProduct),
        },
    }),
)(Component);