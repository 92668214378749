import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .admin-element-editor {
        .editor-wrapper {
            display: block !important;

            .forms-wrapper {
                width: 100% !important;
                padding-right: 0 !important;

                .form-elements {
                    .box {
                        flex-direction: column;
                        width: 48%;

                        &.diagnose, &.trainingTarget {
                            width: 100%;

                            .box-body {
                                .box-input { 
                                    .form-element { 
                                        .input-label {
                                            color: ${variables.dpc_fontDark};
                                            font-size: .9em;
                                            font-weight: 300;   
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    .form-elements {
                        .admin-paginated-list {
                            .list-body {
                                .admin-paginated-list-element {
                                    .wrapper-block {
                                        flex-direction: column;
                                        
                                        .title-block {
                                            justify-content: center;

                                            .title-content {
                                                margin: 0;
                                            }
                                        }

                                        .details-block {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            flex-direction: row-reverse
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    .form-elements {
                        .box {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;
